import * as React from "react"
import { useEffect, useState } from "react";
import { colors } from "../style/style";
import PageHeader from "../components/PageHeader/PageHeader";
import PageSection from "../components/PageSection";
import bgDarkElement from "../static/images/bg-element-dark.svg";
import { fadeInLeft } from "../style/motion-variants";
import SlideInElement from "../components/SlideInElement/SlideInElement";

import {Title, TagLine, Outline} from "../components/PageParts";
import styled from "styled-components";
import { Link } from "gatsby";

const GoHome = styled(Link)`
  padding: 10px 32px;
  border: 2px solid ${ colors.dark };
  border-radius: 50px;
  color: ${ colors.dark };
  font-weight: 700;
  background: transparent;
  text-decoration: none;
`

const NotFoundPage = () => {
  const [ width, setWidth ] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);

    function changeWindowSize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, [])
  return (
      <>
        <PageHeader color={ colors.dark }/>
        <PageSection>
          <SlideInElement
              top={ width <= 1024 ? -130 : -170 }
              left={ width <= 1024 ? -330 : -400 }
              bg={ bgDarkElement }
              variant={ fadeInLeft }/>
          <Title color={colors.dark} style={{display: width <= 768 ? 'flex' : 'block'}}>Error <Outline>404</Outline></Title>
          <TagLine color={colors.dark} style={{marginBottom: "30px"}}>The page you are looking for doesn’s exist.</TagLine>
          <GoHome to={"/"}>Go home</GoHome>
        </PageSection>
      </>

  )
}

export default NotFoundPage
